<div class="container-fluid p-0">
	<div class="row">
		<div class="col-12">
			<div class="login-card">
				<div>
					<div>
						<a class="logo" routerLink="/">
							<img class="img-fluid for-light" src="assets/images/login/login.jpg" alt="looginpage" />
							<img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage" />
						</a>
					</div>
					<div class="login-main">
						<form class="theme-form" [formGroup]="loginForm">
							<h4 class="text-center">Iniciar Sesión </h4>
							<p>Ingrese su usuario y contraseña para iniciar sesión</p>
							<div class="form-group">
								<label class="col-form-label"><span class="text-danger">*</span> Microred</label>
								<select class="form-control" id="basicSelect" formControlName="microred_id">
									<option value="" selected>Seleccione...</option>
									<option *ngFor="let val of microredOptions" [value]="val.id">{{ val.nombre |
										uppercase }}</option>
								</select>
								<div *ngIf="f.microred_id.invalid && (loginForm.get('microred_id').touched || submitted) "
									class="alert alert-danger">
									Seleccione una microred por favor.
								</div>
							</div>
							<div class="form-group">
								<label class="col-form-label"><span class="text-danger">*</span> Ipress</label>
								<select class="form-control" id="basicSelect" formControlName="ipress_id">
									<option value="" selected>Seleccione...</option>
									<option *ngFor="let val of ipressOptions" [value]="val.id">{{ val.sede | uppercase
										}}</option>
								</select>
								<div *ngIf="f.ipress_id.invalid && (loginForm.get('ipress_id').touched || submitted)"
									class="alert alert-danger">
									Debe seleccionar una ipress.
								</div>
							</div>
							<div class="form-group">
								<label class="col-form-label"><span class="text-danger">*</span> Usuario</label>
								<input class="form-control" type="text" required="" placeholder="usuario"
									formControlName="username" autocomplete="off">
								<div *ngIf="f.username.invalid && (loginForm.get('username').touched || submitted)"
									class="alert alert-danger">
									El usuario es requerido
								</div>

							</div>
							<div class="form-group">
								<label class="col-form-label"><span class="text-danger">*</span> Contraseña</label>
								<input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]"
									required="" placeholder="*********" formControlName="password">
								<div class="invalid-feedback"
									*ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">
									La contraseña es requerida.</div>
								<div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span>
								</div>
								<div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span>
								</div>
								<div *ngIf="errorMessage" class="alert alert-danger">
									{{ errorMessage }}
								</div>
							</div>
							<div class="form-group mb-0">
								<div class="checkbox p-0">
									<input id="checkbox1" type="checkbox">
									<label class="text-default" for="checkbox1">Recordarme</label>
								</div>
								<a [routerLink]="'/authentication/forgot-password'" class="link">¿Has olvidado tu
									contraseña?</a>
								<button class="btn btn-primary d-block w-100" type="button"
									(click)="login()">Ingresar</button>
							</div>

							<p class="mt-4 mb-0">No tienes una Cuenta?
								<a [routerLink]="'/authentication/register/image-two'" class="ms-2">Crear una Cuenta</a>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>